<template>
  <div>
    <label for="text" style="margin-right: 5px; white-space: nowrap">{{
      data.label
    }}</label>
    <div>
      <span v-if="isActive || isDataTableField" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
      </span>
    </div>
    <div
      ref="table"
      :style="{
        display: 'grid',
        gridTemplateColumns: columnStyles,
        gridGap: `${cellSpacing}px`,
        border: `${borderSize}px solid black`,
      }"
      class="resizable-table"
    >
      <div
        v-for="(cell, colIndex) in tableCells"
        :key="colIndex"
        :style="{
          border: `${borderSize}px solid black`,
          padding: `${cellPadding}px`,
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }"
      >
        <div
          v-if="colIndex < columns - 1"
          class="resize-handle"
          @mousedown="startResize($event, colIndex)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableView",
  props: ["data", "value", "isActive", "isDataTableField", "isInDataTable"],
  data() {
    return {
      columnWidths: [],
      resizingColumnIndex: null,
      startX: 0,
      startWidths: [],
    };
  },
  computed: {
    rows() {
      return this.data.tableProperties.rows || 3;
    },
    columns() {
      return this.data.tableProperties.columns || 3;
    },
    borderSize() {
      return this.data.tableProperties.borderSize || 1;
    },
    cellSpacing() {
      return this.data.tableProperties.cellSpacing || 2;
    },
    cellPadding() {
      return this.data.tableProperties.cellPadding || 5;
    },
    tableCells() {
      return Array.from(
        { length: this.rows * this.columns },
        (_, i) => `Cell ${i + 1}`
      );
    },
    columnStyles() {
      return this.columnWidths.map((width) => `${width}px`).join(" ");
    },
  },
  mounted() {
    this.initializeColumnWidths();
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    },
    startResize(event, index) {
      this.resizingColumnIndex = index;
      this.startX = event.clientX;
      this.startWidths = [...this.columnWidths];
      document.addEventListener("mousemove", this.handleResize);
      document.addEventListener("mouseup", this.stopResize);
    },
    handleResize(event) {
      if (this.resizingColumnIndex === null) return;

      const deltaX = event.clientX - this.startX;
      const currentWidth = this.startWidths[this.resizingColumnIndex];
      let nextWidth = 0;

      if (this.resizingColumnIndex === this.columnWidths.length - 1) {
        nextWidth = 0;
      } else {
        nextWidth = this.startWidths[this.resizingColumnIndex + 1];
      }

      const newCurrentWidth = Math.max(currentWidth + deltaX, 50);
      const newNextWidth = Math.max(nextWidth - deltaX, 50);

      this.$set(this.columnWidths, this.resizingColumnIndex, newCurrentWidth);

      if (this.resizingColumnIndex < this.columnWidths.length - 1) {
        this.$set(
          this.columnWidths,
          this.resizingColumnIndex + 1,
          newNextWidth
        );
      }

      this.$emit("updateColumnWidths", [...this.columnWidths]);
    },
    initializeColumnWidths() {
      if (this.data.tableProperties.columnWidths) {
        this.columnWidths = [...this.data.tableProperties.columnWidths];
      } else {
        const tableWidth = this.$refs.table.offsetWidth;
        const defaultWidth = tableWidth / this.columns;
        this.columnWidths = Array(this.columns).fill(defaultWidth);
      }
    },

    stopResize() {
      this.resizingColumnIndex = null;
      document.removeEventListener("mousemove", this.handleResize);
      document.removeEventListener("mouseup", this.stopResize);
    },
  },
};
</script>

<style scoped>
.setting-icon {
  cursor: pointer;
  margin-left: 5px;
  color: #409eff;
  font-size: 16px;
}
.setting-icon:hover {
  color: #66b1ff;
}
.resizable-table {
  position: relative;
}
.resize-handle {
  position: absolute;
  top: 0;
  right: -5px;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}
.resize-handle:hover {
  background: rgba(0, 0, 0, 0.2);
}
</style>
