var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',{staticStyle:{"margin-right":"5px","white-space":"nowrap"},attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',[(_vm.isActive || _vm.isDataTableField)?_c('span',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e()]),_c('div',{ref:"table",staticClass:"resizable-table",style:({
      display: 'grid',
      gridTemplateColumns: _vm.columnStyles,
      gridGap: `${_vm.cellSpacing}px`,
      border: `${_vm.borderSize}px solid black`,
    })},_vm._l((_vm.tableCells),function(cell,colIndex){return _c('div',{key:colIndex,style:({
        border: `${_vm.borderSize}px solid black`,
        padding: `${_vm.cellPadding}px`,
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      })},[(colIndex < _vm.columns - 1)?_c('div',{staticClass:"resize-handle",on:{"mousedown":function($event){return _vm.startResize($event, colIndex)}}}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }